import { Context } from '@nuxt/types';

import { ECacheControl } from '@/domain/core/http/CacheControl.enum';
import { EHeader } from '@/domain/core/http/Header';

// NOTE: this is a utility function used as an anonymous middleware to allow passing values, not a named middleware.
// (See : https://v2.nuxt.com/docs/directory-structure/middleware/#named-middleware)
export const _cacheControl = (values: Partial<Record<ECacheControl, string | number | boolean>>) => ({ res }: Context) => {
  if (process.client) {
    return;
  }

  const cacheControlValue = Object.entries(values)
    .map(([key, value]) => {
      let result = `${key}=${value}`;

      if (value === true) { // NOTE: we don't want to verify the truthiness here.
        result = key;
      }

      return result;
    })
    .join(',');

  res?.setHeader(EHeader.CacheControl, cacheControlValue);
};
