
import Vue, { PropType } from 'vue';
// @ts-ignore
import { AisHits } from 'vue-instantsearch';

import { algoliaProductAdapter } from '@/adapters/product.adapter';
import { EHitsThreshold, ESearchInsightEvent } from '@/domain/core/algolia/types';
import { ELocalStorageItem } from '@/domain/core/LocalStorageItem.enum';
import { EBackgroundType } from '@/domain/edito/types';
import { LegacyCategory, ProductCore } from '@/domain/product/types';
import { BaseInteractiveElement } from '@/infrastructure/core/generic/BaseInteractiveElement.interface';
import { AlgoliaIndexResults, AlgoliaProduct } from '@/infrastructure/externalServices/algolia/types';
import { _ga4AlgoliaProductAdapter, _ga4ProductAdapter } from '@/infrastructure/externalServices/ga4/adapters/ga4ProductAdapter';
import { generateItemListIdentifier } from '@/infrastructure/externalServices/ga4/generateItemListIdentifier';
import { GA4ECommerceItemList } from '@/infrastructure/externalServices/ga4/types';
import { EGtmEcommerceListName, EGtmEventCatalog, EGtmEventEcommerce } from '@/infrastructure/externalServices/gtm/DataLayer.enum';
import { ClickEventPayload } from '@/infrastructure/externalServices/gtm/generic/ClickEvent.interface';
import { _isOdd } from '@/utilities/numbers/isOdd';

enum EIndexType {
  MerchItem = 'merchandising-item',
  TextCard = 'reinsurance-text',
}

type IndexType = `${EIndexType}`;

export default Vue.extend({
  name: 'InstantSearchResults',
  components: {
    AisHits,
  },
  props: {
    category: {
      type: Object as PropType<LegacyCategory>,
      default: () => ({} as LegacyCategory),
    },
    shouldInsertRandomText: {
      type: Boolean,
      default: false,
    },
    trackingId: {
      type: String as PropType<`${EGtmEcommerceListName}`>,
      required: true,
    },
  },
  data() {
    return {
      EIndexType,
      itemsCount: 0,
      localNbHits: 0,
      merchItem: {} as BaseInteractiveElement,
      randomIndexes: {
        [EIndexType.MerchItem]: null,
        [EIndexType.TextCard]: null,
      } as Record<IndexType, number | null>,
      timerId: null as ReturnType<typeof setTimeout> | null,
      delay: 300,
    };
  },
  computed: {
    hasCategoryMerchItems(): boolean {
      return !!this.category?.merchandisingItems?.length;
    },
    isUserAdmin(): boolean {
      return this.$accessor.user.isAdmin;
    },
    isUserPro(): boolean {
      return this.$accessor.user.isPro;
    },
    isUserSelectionsManager(): boolean {
      return this.$accessor.user.isSelectionsManager;
    },
    isUserProductBooster(): boolean {
      return this.$accessor.user.isProductBooster;
    },
    itemListIdentifier(): GA4ECommerceItemList {
      let trackingId = this.trackingId;

      if (this.trackingId === EGtmEcommerceListName.Category) {
        trackingId += ` ${this.category.name}`;
      }

      return generateItemListIdentifier(trackingId as `${EGtmEcommerceListName}`);
    },
  },
  beforeDestroy() {
    this.clearTimer();
  },
  methods: {
    clearTimer(): void {
      if (this.timerId) {
        clearTimeout(this.timerId);
      }
    },
    generateRandomIndex(target: IndexType): void {
      const { Min } = EHitsThreshold;
      let index = Math.floor(Math.random() * (this.itemsCount - Min) + Min);

      // index !== Min to avoid infinite loop
      if (index === this.randomIndexes?.[target] && index > Min) {
        this.generateRandomIndex(target);
      }

      if (_isOdd(index) && index !== this.itemsCount) {
        index += 1;
      }

      this.randomIndexes[target] = index;
    },
    generateRandomMerchItem(): void {
      const items = this.category?.merchandisingItems;
      const randomIndex = Math.floor(Math.random() * items?.length || 0);
      const { image, title, link } = items?.[randomIndex] || {};

      this.merchItem = {
        backgroundType: EBackgroundType.Image,
        image,
        title,
        url: link,
      };
    },
    handleTrackingMerchandisingItem(payload: ClickEventPayload): void {
      this.$gtm.push({
        event: EGtmEventCatalog.ClickCategoryMerchandisingItem,
        ...payload,
      });
    },
    transformItems(items: AlgoliaProduct[], { results }: { results: AlgoliaIndexResults }): ProductCore[] {
      const fmtItems = items?.map((item: AlgoliaProduct): ProductCore => algoliaProductAdapter(item)) || [];

      this.updateItemsCount(fmtItems?.length);

      if (process.client) {
        this.storeAlgoliaAbTestInfos(results);
        this.handleTracking(items, results?.nbHits || 0);
      }

      if (this.shouldInsertRandomText) {
        this.generateRandomIndex(EIndexType.TextCard); // Generate a new random index to trigger component re-render.
      }

      if (this.hasCategoryMerchItems) {
        this.generateRandomMerchItem();
        this.generateRandomIndex(EIndexType.MerchItem);
      }

      return fmtItems;
    },
    storeAlgoliaAbTestInfos(results?: AlgoliaIndexResults): void {
      if (results?.abTestID && results?.abTestVariantID) {
        localStorage.setItem(ELocalStorageItem.AlgoliaAbTestInfos, `${results.abTestID}|${results.abTestVariantID}`);
      } else {
        localStorage.removeItem(ELocalStorageItem.AlgoliaAbTestInfos);
      }
    },
    updateItemsCount(count: number): void {
      this.itemsCount = count;
    },
    handleTracking(items: AlgoliaProduct[] = [], nbHits: number): void {
      this.clearTimer();

      if (this.localNbHits !== nbHits) {
        this.$emit('update:nb-hits', nbHits);
        this.localNbHits = nbHits;
      }

      this.timerId = setTimeout(() => {
        const fmtProducts = items.map((item, index) => ({
          ..._ga4AlgoliaProductAdapter(item, index),
          ...this.itemListIdentifier,
        }));

        const abTestInfos = localStorage.getItem(ELocalStorageItem.AlgoliaAbTestInfos);

        this.$gtm.push({ ecommerce: null });
        this.$gtm.push({
          event: EGtmEventEcommerce.ViewItemList,
          ecommerce: {
            ...this.itemListIdentifier,
            items: fmtProducts,
          },
          algolia_ab_test: abTestInfos,
        });
      }, this.delay);
    },
    handleClickProduct(item: ProductCore, position: number): void {
      const fmtProduct = _ga4ProductAdapter(item, position);
      const abTestInfos = localStorage.getItem(ELocalStorageItem.AlgoliaAbTestInfos);

      if (item?.__queryID) {
        this.$services.algoliaSearchInsights.sendClickEvent(ESearchInsightEvent.OpenProductPage, item);
      }

      this.$gtm.push({ ecommerce: null });
      this.$gtm.push({
        event: EGtmEventEcommerce.SelectItem,
        ecommerce: {
          ...this.itemListIdentifier,
          items: [{
            ...this.itemListIdentifier,
            ...fmtProduct,
          }],
        },
        algolia_ab_test: abTestInfos,
      });
    },
  },
});
