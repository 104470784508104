import { render, staticRenderFns } from "./ProductShop.vue?vue&type=template&id=1c196e9d"
import script from "./ProductShop.vue?vue&type=script&lang=ts"
export * from "./ProductShop.vue?vue&type=script&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BadgeSellerLevel: require('/home/runner/work/webapp/webapp/components/molecules/badge/BadgeSellerLevel.vue').default,BaseTooltip: require('/home/runner/work/webapp/webapp/components/atoms/tooltip/BaseTooltip.vue').default,ProductRatingStars: require('/home/runner/work/webapp/webapp/components/molecules/product/ProductRatingStars.vue').default,BaseButton: require('/home/runner/work/webapp/webapp/components/atoms/button/BaseButton.vue').default,BaseLink: require('/home/runner/work/webapp/webapp/components/atoms/link/BaseLink.vue').default,ModalProductShopReviews: require('/home/runner/work/webapp/webapp/components/organisms/modal/ModalProductShopReviews.vue').default})
